<script setup>
// const navigationAlwaysStickyPages = [
//   'company',
//   'professional',
//   'cockpit-type-filter',
// ]
import slideInBottom from '~/composables/animations/slideInBottom'
const fonts =
  'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700&family=Roboto+Mono:wght@500&display=swap'

const bgGrayRoutes = [
  'cockpit-type',
  'my-account',
  'recommendations-vacancies',
  'recommendations-companies',
  'my-company',
  'my-company-criteria',
  'messages',
]

const config = useRuntimeConfig()
const consentCookie = useCookie('consent')
const route = useRoute()
const modalStore = useModalStore()
const slideOverStore = useSlideOverStore()
const configurationStore = useConfigurationStore()
const compareStore = useCompareStore()

const media = ref('print')

// const isNavigationAlwaysSticky = computed(() =>
//   navigationAlwaysStickyPages.includes(route.value.name),
// )

useHead(() => {
  const gtm = config.public.meta.gtmId
  const googleSiteVerificationToken =
    config.public.meta.googleSiteVerificationToken
  const facebookDomainVerification =
    config.public.meta.facebookDomainVerification

  const meta = []
  if (googleSiteVerificationToken) {
    meta.push({
      hid: 'google-site-verification',
      name: 'google-site-verification',
      content: googleSiteVerificationToken,
    })
  }

  if (facebookDomainVerification) {
    meta.push({
      name: 'facebook-domain-verification',
      content: facebookDomainVerification,
    })
  }

  const script = []

  const noscript = [{ innerHTML: `<link rel="stylesheet" href="${fonts}" />` }]

  if (gtm) {
    script.push({
      hid: 'gtag',
      innerHTML:
        'window.dataLayer=window.dataLayer||[];function gtag(){window.dataLayer.push(arguments)}',
      type: 'text/javascript',
      charset: 'utf-8',
    })
    if (consentCookie.value?.statistic || consentCookie.value?.marketing) {
      script.push({
        hid: 'consent',
        innerHTML: `gtag("consent","update",{ad_storage:${consentCookie.value.statistic ? "'granted'" : "'denied'"},analytics_storage:${
          consentCookie.value.statistic ? "'granted'" : "'denied'"
        },functionality_storage:"granted",personalization_storage:${
          consentCookie.value.marketing ? "'granted'" : "'denied'"
        },security_storage:"granted",ad_user_data:${consentCookie.value.marketing ? "'granted'" : "'denied'"},ad_personalization:${consentCookie.value.marketing ? "'granted'" : "'denied'"}});`,
        type: 'text/javascript',
        charset: 'utf-8',
      })
    } else {
      script.push({
        hid: 'consent',
        innerHTML: `gtag("consent","default",{ad_storage:"denied",analytics_storage:"denied",functionality_storage:"denied",personalization_storage:"denied",security_storage:"denied",ad_user_data:"denied",ad_personalization:"denied",wait_for_update:500});gtag("set","ads_data_redaction",true);`,
        type: 'text/javascript',
        charset: 'utf-8',
      })
    }
    // server side tracking adpage
    script.push({
      id: 'heyu-gtm',
      innerHTML: `(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://tagging.heyu.works/script.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "${gtm}");`,
      type: 'text/javascript',
      tagPosition: 'head',
    })
    script.push({
      src: 'https://tagging.heyu.works/adex.js',
      hid: 'adex',
      type: 'text/javascript',
      tagPosition: 'bodyOpen',
    })
    script.push({
      src: 'https://tagging.heyu.works/settings.js',
      hid: 'settings',
      type: 'text/javascript',
      tagPosition: 'bodyOpen',
    })
    noscript.push({
      hid: 'google-tag-manager-noscript-server-side',
      innerHTML: `<iframe src="https://tagging.heyu.works/ns.html?id=${gtm}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      pbody: true,
      tagPosition: 'bodyOpen',
    })
  }
  return {
    meta,
    link: [
      {
        rel: 'dns-prefetch',
        href: 'https://fonts.gstatic.com',
      },
      {
        rel: 'preconnect',
        href: 'https://fonts.gstatic.com',
        crossorigin: true,
      },
      {
        rel: 'preconnect',
        href:
          config.public.cdnURL ||
          config.public.s3URL ||
          'https://bookthat.ams3.digitaloceanspaces.com',
      },
      {
        rel: 'preload',
        href: fonts,
        as: 'style',
      },
      {
        rel: 'stylesheet',
        hid: 'font-face',
        href: fonts,
        as: 'style',
        media: media.value,
        callback: () => (media.value = 'all'),
      },
    ],
    script,
    noscript,
  }
})

await useAsyncData('configuration', configurationStore.getConfiguration)
</script>

<template>
  <div
    class="flex min-h-screen flex-col text-gray-800 antialiased"
    :class="{
      'bg-gray-50': bgGrayRoutes.includes(route.name),
    }"
  >
    <NavigationHeader />

    <div class="grow">
      <slot />
    </div>

    <NavigationFooter />

    <transition name="slideover" mode="out-in">
      <SlideOver v-if="slideOverStore.slideOverActive" />
    </transition>

    <transition name="fade">
      <Modal v-if="modalStore.modalActive" />
    </transition>

    <transition name="fade">
      <Container
        v-if="
          compareStore.selectedCompareItems.length &&
          route.name !== 'recommendations-companies-compare' &&
          route.name !== 'recommendations-vacancies-compare'
        "
        class="py-10 lg:p-10 relative"
      >
        <div
          class="mx-auto fixed !pl-0 right-0 max-w-7xl -ml-4 px-4 lg:-ml-10 lg:px-10 sm:-ml-6 sm:px-6 justify-end flex bottom-0 z-50"
        >
          <button
            class="h-16 bg-secondary group hover:bg-stone-200 rounded-t-xl flex items-center justify-center w-[293px] hover:cursor-pointer"
            :class="slideInBottom(compareStore.selectedCompareItems.length > 0)"
            @click="compareStore.handleCompare"
          >
            <div class="flex items-center gap-x-3">
              <span
                class="uppercase text-white group-hover:text-secondary text-sm"
                >Bekijk vergelijking
              </span>

              <span
                class="ml-2 flex p-2 w-fit items-center justify-center rounded-full text-xs transition-all bg-accent"
              >
                {{ `${compareStore.selectedCompareItems.length}/3` }}</span
              >
            </div>
          </button>
          <div class="relative">
            <div
              class="absolute -top-2 -right-2 p-1 bg-accent rounded-full hover:cursor-pointer hover:scale-150"
              @click="compareStore.clearSelectedItems()"
            >
              <IconClose class="w-4 h-4 text-black" />
            </div>
          </div>
        </div>
      </Container>
    </transition>

    <CookieNotice />
  </div>
</template>

<style>
.slideover-enter-active section,
.slideover-leave-active section,
.slideover-enter-active i,
.slideover-leave-active i {
  transition: all 0.25s;
}
.slideover-enter-from section,
.slideover-leave-to section {
  @apply opacity-0 translate-x-full;
}
.slideover-enter-from i,
.slideover-leave-to i,
.slideover-enter-from i,
.slideover-leave-to i {
  @apply opacity-0;
}
</style>
