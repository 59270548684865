<script setup>
const emit = defineEmits(['close'])

const refreshAfterSecs = 20

onMounted(() => {
  setTimeout(() => {
    emit('close', true)
  }, refreshAfterSecs * 1000)
})
</script>

<template>
  <div
    class="relative flex h-10 w-full items-center justify-center bg-secondary"
  >
    <IconClose
      class="absolute right-4 top-1/2 h-3 w-3 -translate-y-1/2 cursor-pointer text-white"
      @click="emit('close')"
    />
    <span class="text-xs font-semibold text-accent">
      ✓ Je hebt een assessment afgerond! Het kan enkele ogenblikken duren
      voordat deze is verwerkt.
    </span>
  </div>
</template>
