<script setup>
const emit = defineEmits(['logout'])
defineProps({
  avatarMenu: {
    type: Array,
    required: true,
  },
})

const user = useStrapiUser()
const { upload } = useUploadUtils()
const { isExternal } = useUrl()

const isProfessional = computed(() => user.value?.type === 'professional')
const image = computed(() => {
  let image = null
  if (isProfessional.value) {
    image = user.value?.contact?.image
  } else {
    image = user.value?.company?.logo
  }

  return image ? upload(image, 'small') : '/images/avatar.svg'
})
const link = computed(() => {
  if (user.value?.type === 'professional' && user.value?.professional) {
    return {
      name: 'professionals-id',
      params: { id: user.value.professional.id },
    }
  }

  if (user.value?.type === 'company' && user.value.company) {
    return {
      name: 'companies-slug',
      params: { slug: user.value.company.slug },
    }
  }

  return {
    name: 'my-account',
  }
})
</script>

<template>
  <div class="group relative flex-none">
    <NuxtLink
      v-if="link !== null"
      :to="link"
      class="flex h-10 w-10 items-center flex-col"
    >
      <div
        class="relative flex flex-col h-10 w-10 overflow-hidden rounded-full bg-accent shrink-0"
      >
        <img
          :src="image"
          class="absolute left-0 top-0 h-full w-full object-cover"
        />
      </div>
      <span
        class="font-sans text-gray-700 text-xs items-center justify-center mt-1 hidden lg:flex"
      >
        <IconChevronDown class="h-2.5 w-2.5" />
      </span>
    </NuxtLink>

    <div
      class="invisible absolute -right-4 top-full hidden pt-3 font-mono text-sm opacity-0 transition-all group-hover:visible group-hover:opacity-100 lg:block"
    >
      <i class="absolute right-7 top-2 h-4 w-4 rotate-45 bg-white"></i>
      <div
        class="flex w-[220px] flex-col gap-y-6 rounded-lg bg-white p-6 shadow-xl"
      >
        <NuxtLink
          v-for="(item, index) in avatarMenu"
          :key="index"
          :to="item.url"
          :target="isExternal(item.url) ? '_blank' : null"
          class="whitespace-nowrap text-gray-600 transition-all hover:cursor-pointer hover:text-gray-800"
        >
          {{ item.title }}
        </NuxtLink>
        <a
          href="javascript:void(0)"
          class="whitespace-nowrap text-gray-600 transition-all hover:cursor-pointer hover:text-gray-800"
          @click="emit('logout')"
          >Uitloggen
        </a>
      </div>
    </div>
  </div>
</template>
