<script setup>
const slideOverStore = useSlideOverStore()
</script>

<template>
  <component
    :is="`SlideOver${slideOverStore.slideOverType}`"
    :slide-over-data="slideOverStore.slideOverData"
  ></component>
</template>
