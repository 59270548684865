<script setup>
import { onClickOutside } from '@vueuse/core'

const emit = defineEmits(['clickOutside'])

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  bubbleAlignmentPercentage: {
    type: Number,
    required: false,
    default: 50,
  },
})

const target = ref(null)

const filteredItems = computed(() => props.items.filter((item) => item?.url))

onClickOutside(target, () => emit('clickOutside'))
</script>

<template>
  <div ref="target" class="flex w-max flex-col">
    <div
      v-if="bubbleAlignmentPercentage"
      class="relative z-[21] mx-auto hidden w-4/5 items-center lg:flex"
    >
      <div
        class="absolute h-3 w-3 -translate-x-1/2 rotate-45 bg-white"
        :style="{ left: bubbleAlignmentPercentage + '%' }"
      ></div>
    </div>
    <div
      class="relative z-20 ml-3 mt-2 flex w-full flex-col gap-y-2 lg:m-0 lg:gap-y-6 lg:rounded-lg lg:bg-white lg:p-6 lg:shadow-xl"
    >
      <NuxtLink
        v-for="(item, index) in filteredItems"
        :key="index"
        :to="item?.url"
        class="text-gray-600 transition-all hover:cursor-pointer hover:text-gray-800"
      >
        {{ item.title }}
      </NuxtLink>
    </div>
  </div>
</template>
