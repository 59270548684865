<script setup>
const user = useStrapiUser()
const { getFooterNavigation } = useApiNavigation()
const { formatMarkdown } = useFormatters()
const modalStore = useModalStore()

const configurationStore = useConfigurationStore()

const { data: footerNavigation } = await useAsyncData(
  'footerNavigation',
  getFooterNavigation,
  { watch: [user] },
)

const footer = computed(() => footerNavigation.value?.footer)
const bottom = computed(() => footerNavigation.value?.bottom)
const configuration = computed(() => configurationStore.configuration)

function setCustomCookies() {
  modalStore.openModal('CustomCookies')
}
</script>

<template>
  <footer class="relative z-10 border-b-[48px] border-b-gray-800 text-gray-600">
    <BaseContainer class="-mb-[48px] !px-0 sm:!px-0 md:!px-6" size="md">
      <div
        class="rounded-t-2xl bg-primary px-4 py-8 shadow-lg shadow-gray-800/10 sm:px-6 lg:rounded-t-3xl lg:!p-14"
      >
        <div class="gap-x-5 lg:grid lg:grid-cols-3">
          <div class="mb-6 lg:mb-0">
            <header>
              <BaseLogo class="max-w-[120px] text-gray-800 lg:max-w-[160px]" />
            </header>
          </div>

          <div v-if="footer?.items?.length" class="mb-8 lg:mb-0">
            <header class="mb-6">
              <h2 class="!font-sans text-base text-white">Direct naar</h2>
            </header>
            <div class="grid grid-cols-2 gap-y-6">
              <NuxtLink
                v-for="(item, index) in footer?.items"
                :key="index"
                :to="item.url"
                class="text-sm hover:text-gray-800"
              >
                <span>{{ item.title }}</span>
              </NuxtLink>
            </div>
          </div>
          <div v-else></div>

          <div class="lg:pl-24">
            <header class="mb-6">
              <h2 class="!font-sans text-base text-white">Contact</h2>
            </header>
            <div class="text-sm">
              <div v-if="configuration?.email" class="mb-6">
                <a
                  :href="`mailto:${configuration?.email}`"
                  class="text-gray-600 hover:text-secondary"
                  >{{ configuration?.email }}
                </a>
              </div>
              <div v-if="configuration?.address" class="mb-6">
                <p>
                  {{ configuration?.address }}
                </p>
              </div>
              <div v-if="configuration?.zipCode || configuration?.city">
                <p>{{ configuration?.zipCode }} {{ configuration?.city }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-12 text-xs">
          <div class="flex flex-col-reverse flex-wrap items-start sm:flex-row">
            <span class="mt-3 sm:mt-0 sm:mr-6">
              &copy;{{ new Date().getFullYear() }}
              {{ configuration?.name }}
            </span>
            <div
              class="grid w-full grid-cols-2 items-start gap-3 sm:flex sm:w-auto"
            >
              <div
                v-for="(item, index) in bottom?.items"
                :key="index"
                class="flex"
              >
                <NuxtLink :to="item.url" class="hover:text-gray-800">
                  <span>{{ item.title }}</span>
                </NuxtLink>
                <span
                  v-if="index < bottom?.items?.length - 1"
                  class="ml-3 hidden sm:flex"
                >
                  •
                </span>
              </div>
              •
              <span class="hover:cursor-pointer" @click="setCustomCookies"
                >Wijzig cookies</span
              >
            </div>
          </div>
          <div v-if="bottom?.content" class="mt-6 max-w-2xl">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="formatMarkdown(bottom?.content)"></div>
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>
      </div>
    </BaseContainer>
  </footer>
</template>
