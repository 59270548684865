// used by the notification in header
export const useMessageStore = defineStore('message', () => {
  const apiMessage = useApiMessage()

  const messages = ref([])

  async function getOverview() {
    const data = await apiMessage.getOverview()
    messages.value = data
  }

  return {
    getOverview,
    messages,
  }
})
