<script setup>
const modalStore = useModalStore()

onMounted(() => {
  document.body.classList.add('overflow-y-hidden')
})

onUnmounted(() => {
  document.body.classList.remove('overflow-y-hidden')
})
</script>

<template>
  <div class="fixed inset-0 z-50 overflow-y-auto">
    <div class="relative w-full">
      <div
        v-if="modalStore.modalType"
        class="relative flex min-h-screen justify-center"
      >
        <i
          class="fixed inset-0 bg-slate-900/40"
          @click="modalStore.setModalClose"
        ></i>
        <component
          :is="'Modal' + modalStore.modalType"
          class="relative top-16"
        />
      </div>
    </div>
  </div>
</template>
