<script setup>
const user = useStrapiUser()
const { upload } = useUploadUtils()

defineProps({
  message: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const userType = computed(() => {
  return user.value ? user.value?.type : ''
})

function onClickNotificationItem(id) {
  return navigateTo({
    name: 'messages',
    query: {
      id,
    },
  })
}
</script>

<template>
  <div
    class="mt-2 flex w-full cursor-pointer rounded-lg bg-gray-50 p-4 first:mt-0 hover:bg-gray-300"
    @click="
      onClickNotificationItem(
        userType && userType === 'professional'
          ? message?.company?.id
          : message?.professional?.id,
      )
    "
  >
    <div>
      <figure
        v-if="
          message?.company ||
          (message?.professional &&
            message?.professional?.user?.contact?.first_name !== undefined)
        "
        class="relative flex h-[56px] w-[56px] items-center justify-center overflow-hidden rounded-lg border-b-gray-300 bg-white"
      >
        <img
          :src="
            userType === 'professional'
              ? upload(message?.company?.logo, 'thumbnail')
              : upload(message?.professional?.user?.contact?.image, 'thumbnail')
          "
          alt=""
          class="h-3/5 w-3/5 object-cover"
        />
      </figure>
      <div
        v-else
        class="flex h-[56px] w-[56px] items-center justify-center overflow-hidden rounded-lg bg-white"
      >
        <BaseLogoFull class="h-4/5 w-4/5 object-cover text-secondary" />
      </div>
    </div>
    <div class="ml-4 flex w-full flex-col pr-4">
      <p class="mb-4 font-sans text-base text-gray-700">
        <span class="font-semibold text-gray-800">
          {{
            userType === 'professional'
              ? message?.company?.name
              : `${
                  message?.professional?.user?.contact?.first_name ||
                  `Professional #${message?.professional?.id} `
                } ${message?.professional?.user?.contact?.last_name || ''}`
          }}
        </span>
        heeft je een nieuw bericht gestuurd
      </p>
      <p class="font-sans text-sm font-semibold text-secondary">
        Bekijk bericht
      </p>
    </div>
  </div>
</template>
