<script setup>
const route = useRoute()
const router = useRouter()
const { width } = useWindowSize()
const user = useStrapiUser()
const { logout } = useStrapiAuth()
const { isExternal } = useUrl()
const { getHeaderNavigation } = useApiNavigation()
const cockpitStore = useCockpitStore()
const messageStore = useMessageStore()
const configurationStore = useConfigurationStore()

const navigationOverLayVisible = ref(false)
const navigationIsSticky = ref(false)

const { data: headerNavigation } = await useAsyncData(
  'headerNavigation',
  getHeaderNavigation,
  { watch: [user] },
)

await useLazyAsyncData('cockpit-header', cockpitStore.getCockpit, {
  watch: [user],
  server: false,
})

const { refresh: refreshMessages } = await useAsyncData(
  'messagesHeader',
  messageStore.getOverview,
  { watch: [user] },
)

const header = computed(() => headerNavigation.value?.header)
const avatarMenu = computed(() => headerNavigation.value?.avatarMenu)
const logo = computed(() => headerNavigation.value?.logo)
const logoUrl = computed(() => {
  switch (user.value?.type) {
    case 'company':
      return logo.value?.companyUrl
    case 'professional':
      return logo.value?.professionalUrl
    default:
      return logo.value?.publicUrl
  }
})

const showReturnFromAssessmentBar = computed(() => {
  return route.value?.query?.returnedFromAssessment
})

const userType = computed(() => user.value?.type || '')
const isProfessional = computed(() => userType.value === 'professional')
const onboardingFinished = computed(() => {
  return (
    (user.value?.type === 'company' && user.value.company.finishedOnboarding) ||
    (user.value?.type === 'professional' &&
      user.value.professional.finishedOnboarding)
  )
})
const messages = computed(() => messageStore.messages)
const cockpitCounts = computed(() => {
  const items = cockpitStore.items
  const filtersToCount = [
    'interested_in_us',
    'match',
    'public_match',
    'appointment',
  ]

  let count = 0
  for (const filterToCount of filtersToCount) {
    if (Array.isArray(items?.[filterToCount])) {
      count += items[filterToCount].length
    } else {
      count += items?.[filterToCount]?.companyCockpit.cockpit.length
      count += items?.[filterToCount]?.vacancyCockpit.flatMap(
        (vacancy) => vacancy.cockpit,
      ).length
      count += items?.[filterToCount]?.talentpoolCockpit.flatMap(
        (talentpool) => talentpool.cockpit,
      ).length
    }
  }

  return count
})

function toggleNavigation() {
  if (width.value > 1024) {
    return
  }

  navigationOverLayVisible.value = !navigationOverLayVisible.value
}

function logoutUser() {
  logout()
  return navigateTo({ name: 'login' })
}

function handleScroll() {
  if (process.client) {
    window.addEventListener('scroll', () => {
      if (window.top.scrollY > 0) {
        navigationIsSticky.value = true
      } else {
        navigationIsSticky.value = false
      }
    })
  }
}

async function closeReturnFromAssessmentBar(reload) {
  await router.replace(route.value.path)
  if (reload) window.location.reload()
}

function pollMessages() {
  // Every two minutes
  const timeout = 1000 * 60 * 2
  setTimeout(async () => {
    if (user.value) {
      await refreshMessages()
    }
    pollMessages()
  }, timeout)
}

onMounted(() => {
  handleScroll()
  pollMessages()
})
</script>

<template>
  <nav
    class="sticky top-0 z-30 bg-primary transition-all"
    :class="{
      'bg-primary shadow-lg shadow-gray-800/5': navigationIsSticky,
      '!rounded-none bg-primary': navigationOverLayVisible,
    }"
  >
    <NavigationReturnFromAssessmentBar
      v-if="showReturnFromAssessmentBar"
      @close="closeReturnFromAssessmentBar"
    />
    <BaseContainer class="relative py-2 sm:py-7">
      <div class="flex items-center justify-between">
        <NuxtLink :to="logoUrl || ''">
          <BaseLogo class="h-[32px] text-secondary sm:h-[44px]" />
        </NuxtLink>
        <div
          class="absolute left-0 right-0 top-[62px] ml-auto flex max-h-[calc(100vh-62px)] flex-col overflow-y-auto rounded-b-2xl bg-white p-3 transition-all duration-300 sm:top-[102px] sm:max-h-[calc(100vh-102px)] sm:p-6 lg:static lg:max-h-full lg:flex-row lg:items-center lg:gap-x-4 lg:overflow-y-visible lg:rounded-none lg:bg-transparent lg:p-0 xl:gap-x-6"
          :class="[
            {
              visible: navigationOverLayVisible,
            },
            {
              'invisible opacity-0 lg:visible lg:opacity-100':
                !navigationOverLayVisible,
            },
          ]"
        >
          <div
            v-if="header && header?.items"
            class="flex w-full flex-1 flex-col items-stretch gap-x-6 gap-y-3 font-mono text-sm transition-all lg:w-auto lg:flex-row xl:gap-x-8"
          >
            <div
              v-for="(item, index) in header.items"
              :key="index"
              class="group flex items-center whitespace-nowrap text-gray-600 hover:text-gray-800"
            >
              <div
                class="relative flex h-full flex-col lg:flex-row lg:items-center"
              >
                <NuxtLink
                  :to="item.url"
                  class="top-level-link flex h-full items-center border-b-2 border-transparent uppercase"
                  :class="{
                    'rounded-xl bg-secondary px-6 py-4 font-mono text-xs leading-none text-stone-200 hover:bg-stone-200 hover:text-secondary':
                      item.buttonTheme,
                  }"
                  :target="isExternal(item.url) ? '_blank' : null"
                  @click="toggleNavigation"
                >
                  <span>{{ item.title }}</span>
                  <div
                    v-if="item.url === '/cockpit' && cockpitCounts"
                    class="ml-2 flex h-[24px] min-w-[32px] items-center justify-center rounded-full bg-secondary px-2"
                  >
                    <span class="text-xs leading-3 text-white">
                      {{ cockpitCounts }}
                    </span>
                  </div>
                </NuxtLink>

                <NavigationMenu
                  v-if="item?.children.length"
                  :items="item?.children"
                  class="z-10 text-left lg:absolute lg:left-1/2 lg:top-6 lg:hidden lg:min-w-[220px] lg:-translate-x-1/2 lg:pt-2 lg:group-hover:flex"
                  @click="toggleNavigation"
                />
              </div>
            </div>
          </div>

          <NavigationNotification
            v-if="user && configurationStore.configuration?.loginEnabled"
            class="hidden lg:flex"
            :unread-messages="
              messages.filter(
                (message) =>
                  message.initiator !== userType && !message.receiverRead,
              )
            "
          />

          <div class="mt-3 flex sm:mt-6 lg:mt-0">
            <div
              v-if="user && configurationStore.configuration?.loginEnabled"
              class="flex w-full flex-col items-center"
            >
              <div class="self-start lg:hidden">
                <NuxtLink
                  v-for="(item, index) in avatarMenu?.items ?? []"
                  :key="index"
                  :to="item.url"
                  :target="isExternal(item.url) ? '_blank' : null"
                  class="flex whitespace-nowrap px-5 py-1 transition-all hover:text-gray-900"
                  @click="toggleNavigation"
                >
                  {{ item.title }}
                </NuxtLink>
                <a
                  href="javascript:void(0)"
                  class="text-pink hover:text-pink-dark flex whitespace-nowrap px-5 py-1 transition-all hover:text-gray-900"
                  @click="logoutUser()"
                >
                  Uitloggen
                </a>
              </div>

              <div class="flex items-center gap-4">
                <NavigationAvatar
                  :avatar-menu="avatarMenu?.items ?? []"
                  @logout="logoutUser"
                  @click="toggleNavigation"
                />
                <Button
                  v-if="!onboardingFinished"
                  :path="
                    isProfessional
                      ? { name: 'onboarding-professional' }
                      : { name: 'onboarding-company' }
                  "
                  theme="secondary"
                  text="Voltooi profiel"
                  size="md"
                  @click="toggleNavigation"
                />
              </div>
            </div>

            <div v-else class="flex w-full items-center gap-4">
              <Button
                v-if="configurationStore.configuration?.loginEnabled"
                theme="transparent"
                text="Log in"
                :path="{ name: 'login' }"
                class="w-full md:w-auto"
                @click="toggleNavigation"
              />
              <Button
                v-if="configurationStore.configuration?.registrationEnabled"
                theme="accent"
                text="Registreren"
                :path="{ name: 'register' }"
                class="w-full md:w-auto"
                @click="toggleNavigation"
              />
            </div>
          </div>
        </div>

        <div class="flex items-center gap-4 lg:hidden">
          <Button
            v-if="!user"
            theme="accent"
            text="Registreren"
            :path="{ name: 'register' }"
            class="w-full md:w-auto"
          />
          <button
            class="relative -mr-3 flex h-[46px] w-[46px]"
            aria-label="Open menu"
            title="Open Menu"
            @click="toggleNavigation"
          >
            <span
              class="absolute right-3 top-1/2 block h-[2px] w-6 transform bg-secondary transition-all"
              :class="[
                { '-rotate-45': navigationOverLayVisible },
                { '-translate-y-1': !navigationOverLayVisible },
              ]"
            ></span>
            <span
              class="absolute right-3 top-1/2 block h-[2px] w-6 transform bg-secondary transition-all"
              :class="[
                { 'rotate-45': navigationOverLayVisible },
                { 'translate-y-1': !navigationOverLayVisible },
              ]"
            ></span>
          </button>

          <NavigationNotification
            v-if="user && configurationStore.configuration?.loginEnabled"
            :unread-messages="
              messages.filter(
                (message) =>
                  message.initiator !== userType && !message.receiverRead,
              )
            "
          />

          <NavigationAvatar
            v-if="user"
            :avatar-menu="[]"
            @logout="logoutUser"
          />
        </div>
      </div>
    </BaseContainer>
  </nav>
</template>

<style scoped>
.nuxt-link-exact-active {
  @apply text-secondary;
}

.nuxt-link-exact-active.bg-secondary {
  @apply text-white hover:text-secondary;
}
</style>
