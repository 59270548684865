<script setup>
const noticeShown = ref(false)
const elevenMonthsFromNow = new Date()
elevenMonthsFromNow.setMonth(elevenMonthsFromNow.getMonth() + 11)
const consentCookie = useCookie('consent', {
  expires: elevenMonthsFromNow,
  maxAge: 11 * 30 * 24 * 60 * 60,
})
const modalStore = useModalStore()

function loadDataLayer() {
  if (process.client) {
    window.dataLayer = window.dataLayer || []
  }
}

function gtag() {
  window.dataLayer.push(arguments)
}

function setConsent() {
  gtag('consent', 'update', {
    ad_storage: 'granted',
    analytics_storage: 'granted',
    functionality_storage: 'granted',
    security_storage: 'granted',
    personalization_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
  })
}

function hideNotice() {
  document.body.classList.remove('overflow-y-hidden')
  noticeShown.value = false
}

function acceptNotice() {
  consentCookie.value = {
    statistic: true,
    marketing: true,
  }
  hideNotice()
  setConsent()
}

onMounted(() => {
  loadDataLayer()

  if (consentCookie.value === null || consentCookie.value === undefined) {
    noticeShown.value = true
    document.body.classList.add('overflow-y-hidden')
  }
})

function setCustomCookies() {
  modalStore.openModal('CustomCookies', {
    callback: () => hideNotice(),
  })
}

onUnmounted(() => {
  document.body.classList.remove('overflow-y-hidden')
})
</script>

<template>
  <div
    v-if="noticeShown"
    class="fixed top-0 bottom-0 right-0 left-0 z-40 p-4 font-mono text-white drop-shadow sm:p-6 flex items-center justify-center bg-slate-700/50"
  >
    <div class="rounded-xl bg-secondary p-4 sm:p-6 max-w-96">
      <header>
        <h2 class="text-2xl font-bold uppercase text-accent sm:text-3xl">
          Cookies
        </h2>
        <p class="text-xs">
          Wij maken gebruik van cookies. Door gebruik te maken van deze site
          stem je hiermee in. Voor meer informatie, lees ons
          <NuxtLink to="/privacy-statement" class="underline"
            >privacy statement</NuxtLink
          >.
        </p>
      </header>
      <footer class="mt-4 flex flex-col gap-2">
        <Button
          theme="accent"
          text="Accepteren"
          size="sm"
          class="w-full"
          @click="acceptNotice"
        />
        <button
          class="w-full text-tertiary/80 underline uppercase text-xs"
          @click="setCustomCookies"
        >
          Voorkeuren
        </button>
      </footer>
    </div>
  </div>
</template>
