<script setup>
defineProps({
  unreadMessages: {
    type: Array,
    required: true,
  },
})

const overlayVisible = ref(false)

function toggleOverlay() {
  overlayVisible.value = !overlayVisible.value
}
</script>

<template>
  <div
    class="group relative flex h-[40px] min-h-[40px] w-[40px] min-w-[40px] items-center justify-center rounded-full bg-white"
    @click="toggleOverlay"
  >
    <IconNotification class="h-4 text-gray-600" />
    <p
      v-if="unreadMessages && unreadMessages.length > 0"
      class="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white"
    >
      {{ unreadMessages.length }}
    </p>

    <div
      class="hidden absolute -right-10 top-full pt-3 font-mono text-sm opacity-0 transition-all group-hover:block group-hover:opacity-100 lg:-right-4"
      :class="{
        'block opacity-100': overlayVisible,
      }"
    >
      <i
        class="absolute right-[3.4rem] top-2 h-4 w-4 rotate-45 bg-white lg:right-7"
      ></i>
      <div
        class="flex w-[calc(100vw-60px)] max-w-[420px] flex-col rounded-lg bg-white shadow-xl lg:w-[420px]"
      >
        <div class="border-b border-gray-200 p-4">
          <h2 class="font-sans text-lg font-semibold text-secondary">Inbox</h2>
        </div>
        <div v-if="unreadMessages?.length > 0" class="p-4">
          <MessagesNotificationCard
            v-for="cardMessage in unreadMessages"
            :key="cardMessage.id"
            :message="cardMessage"
          />
        </div>
        <div v-else class="p-4">
          <p class="text-center font-sans text-base">
            Er zijn geen nieuwe meldingen
          </p>
        </div>
        <div
          class="pb-4 pl-4 pr-4"
          :class="{ 'flex justify-center': unreadMessages.length === 0 }"
        >
          <NuxtLink
            to="/messages"
            class="font-sans text-base font-medium text-secondary underline"
          >
            Ga naar inbox
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
