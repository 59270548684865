import type { NavigationResponse } from '~/types/__generated__'

const NAVIGATION_HEADER_POPULATE = ['header', 'avatarMenu', 'logo']
const NAVIGATION_FOOTER_POPULATE = ['footer', 'bottom']

export function useApiNavigation() {
  const client = useStrapiClient()
  async function getHeaderNavigation() {
    const { data } = await client<NavigationResponse>('/navigation', {
      method: 'GET',
      params: {
        populate: NAVIGATION_HEADER_POPULATE,
      },
    })
    return data
  }

  async function getFooterNavigation() {
    const { data } = await client<NavigationResponse>('/navigation', {
      method: 'GET',
      params: {
        populate: NAVIGATION_FOOTER_POPULATE,
      },
    })
    return data
  }

  return {
    getHeaderNavigation,
    getFooterNavigation,
  }
}
