<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-2, -2)">
        <g transform="translate(2, 2)">
          <polygon points="0 0 20 0 20 20 0 20"></polygon>
          <path
            d="M11.8625,15.8333333 C12.5833333,15.8333333 12.9641667,16.6866667 12.4833333,17.2233333 C11.8515598,17.9303155 10.9481355,18.3341279 10,18.3333333 C9.05186449,18.3341279 8.14844022,17.9303155 7.51666667,17.2233333 C7.05666667,16.71 7.385,15.9075 8.045,15.8391667 L8.13666667,15.8341667 L11.8625,15.8341667 L11.8625,15.8333333 Z"
            fill="#627D98"
            fill-rule="nonzero"
          ></path>
          <path
            d="M10,1.66666667 C11.1316667,1.66666667 12.0883333,2.41916667 12.3958333,3.45083333 L12.4341667,3.59333333 L12.4408333,3.62916667 C14.3043012,4.6804237 15.551178,6.56096436 15.7941667,8.68666667 L15.8175,8.92583333 L15.8333333,9.16666667 L15.8333333,11.6091667 L15.8508333,11.7225 C15.9649367,12.3365186 16.3047274,12.8855942 16.8033333,13.2616667 L16.9425,13.3591667 L17.0775,13.4416667 C17.7941667,13.8475 17.5441667,14.9133333 16.7633333,14.995 L16.6666667,15 L3.33333333,15 C2.47666667,15 2.1775,13.8633333 2.9225,13.4416667 C3.56524954,13.0779287 4.01428224,12.4486119 4.14916667,11.7225 L4.16666667,11.6033333 L4.1675,9.12833333 C4.2706711,6.91825533 5.46357344,4.90333994 7.35166667,3.75 L7.55833333,3.62833333 L7.56666667,3.5925 C7.80811163,2.57183705 8.6604131,1.81002191 9.70166667,1.68416667 L9.85333333,1.67 L10,1.66666667 L10,1.66666667 Z"
            fill="#627D98"
            fill-rule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
